<template>
    <v-container fluid>
        <v-card
            width="1020"
            class="mx-auto pa-4"
            elevation="2"
            outlined
        >
            <v-card-title>
                Teachers
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details=""
                    clearable
                    class="mr-12"                    
                ></v-text-field>                
                <v-btn
                    color="primary ml-6 pt-2"                    
                    text
                    outlined
                    class="mb-2"
                    @click="addNewTeacher" 
                    v-if="!disable"                  
                >
                    Add New Teacher
                </v-btn>
            </v-card-title>

            <v-tabs
                v-model="tab"
                color="primary"
            >
                <v-tab>
                    Current Teachers
                </v-tab>

                <v-tab>Archive</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card 
                        flat                        
                    >                    
                        <v-data-table
                            class="mt-10"
                            :headers="headers"
                            :items="employees"
                            :search="search"
                            fixed-header
                            height="55vh"
                            :loading="loadingEmployees"
                            calculate-widths
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    @click="editEmployee(item)"
                                    x-small
                                    text
                                    outlined
                                    class="mr-2"
                                    v-if="!disable"
                                >
                                    <span
                                        class="mr-2"
                                    >Edit</span>
                                    <v-icon
                                        x-small
                                        class="mr-2 "
                                        
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>

                                <TeacherLessons
                                    v-bind:employee="item"
                                    v-bind:formClasses="formClasses"
                                    @update-form-classes="updateFormClasses"
                                ></TeacherLessons>

                                <v-btn
                                    @click="displayResetPassword(item)"
                                    x-small
                                    text
                                    outlined
                                    v-if="!disableReset"
                                >
                                    <span
                                        class="mr-2"
                                    >Reset</span>
                                    <v-icon
                                        x-small
                                        class="mr-2"
                                        
                                    >
                                        mdi-lock-reset
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>

                <v-tab-item>
                    <v-card 
                        flat
                    >                    
                        <v-data-table
                            class="mt-10"
                            :headers="headersArchive"
                            :items="employeesArchive"
                            :search="search"
                            fixed-header
                            height="55vh"
                            :loading="loadingEmployees"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    @click="editEmployeeArchive(item)"
                                    x-small
                                    text
                                    outlined
                                    class="mr-2"
                                    v-if="!disable"
                                >
                                    <span
                                        class="mr-2"
                                    >Edit</span>
                                    <v-icon
                                        x-small
                                        class="mr-2 "
                                        
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>                                
                               
                            </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
                
            </v-tabs-items>
           
            <!-- Edit Employee -->
            <v-dialog
                v-model="dialogEdit"
                max-width="500px"
                persistent
            >
                <v-card class="pa-2" ref="form">
                    <v-card-title>
                        {{ formTitle }}
                    </v-card-title>
                    <v-card-text>
                        <v-alert
                            v-if="alert.display"
                            dense
                            text
                            :type="alert.type"
                        >
                            {{ alert.text }}
                        </v-alert>
                        <v-row>
                            <v-col class="pt-0">
                                <v-text-field
                                    label="Teacher No"
                                    v-model="editedEmployee.teacher_num"                                   
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <v-text-field
                                    label="First Name"
                                    v-model="editedEmployee.first_name"
                                    :rules = "rules" 
                                    ref="firstName"                                 
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <v-text-field
                                    label="Last Name"
                                    v-model="editedEmployee.last_name" 
                                    :rules = "rules"   
                                    ref="lastName"                                
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <v-menu               
                                    ref="menuBirthDate"
                                    v-model="menuBirthDate"
                                    :close-on-content-click="false"                           
                                    transition="scale-transistion"
                                    offset-y
                                    min-width="290px"                            
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="editedEmployee.date_of_birth"
                                            label="Date of Birth"                                            
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            :max="new Date().toISOString().substr(0, 10)"
                                            autocomplete="false" 
                                            :rules = "rules" 
                                            ref="dateOfBirth"                                                  
                                        >
                                            
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="pickerDateOfBirth"
                                        v-model="editedEmployee.date_of_birth"
                                        @change="saveDateOfBirth"
                                    >                                
                                    </v-date-picker>                            
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                <v-select
                                    label="Post"
                                    v-model="editedEmployee.post_id"
                                    :items="employeePosts"
                                    item-text="rank"
                                    item-value="id"                                    
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="px-4">
                        <!-- <span>
                            <span>{{ saveProgress }}</span>
                            <v-progress-circular
                                indeterminate
                                size="16"
                                width="3"
                                v-show="saving"
                                class="ml-2"
                            ></v-progress-circular>
                            <v-icon
                                small
                                color="green" 
                                v-show="saved"
                                class="mx-2"
                            >
                                mdi-check-all
                            </v-icon>
                            <v-icon
                                small
                                color="red"
                                v-show="saveError"
                                class="mx-2"
                            >
                                mdi-alert-circle
                            </v-icon>
                        </span> -->
                        
                        <v-spacer></v-spacer>                        
                        <v-btn
                            depressed
                            @click="closeEdit"
                            class="mr-3"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            v-if="resetForm"
                            outlined
                            class="mr-3"
                            @click="setClearForm"
                        >
                            Clear Form
                        </v-btn>
                        <v-btn
                            color="primary"
                            depressed
                            @click="saveEmployee"
                        >
                            {{ saveButtonText }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <!-- Reset Password -->
            <v-dialog
                v-model="dialogResetPassword" 
                max-width="600px"
                persistent
            >
                <v-card>
                    <v-card-title 
                        class="subtitle-1"
                    > 
                        {{ dialogResetTitle }}                               
                    </v-card-title>
                    <v-card-text
                        v-if="resetProgress"
                    >
                        <v-progress-linear
                            color="primary"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="primary" 
                            text
                            outlined
                            @click="closeResetPassword"
                            small                            
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            dark
                            @click="resetPasswordConfirm"
                            class="ml-6"
                            small                            
                        >
                            Ok
                        </v-btn>                        
                    </v-card-actions>                                     
                </v-card>
            </v-dialog>

        </v-card>
        <v-snackbar
            v-model="snackbar"
            color="primary"
        >
            {{ snackbarText }}
        </v-snackbar>
    </v-container>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import TeacherLessons from './AdminEmployeesLessons.vue';
export default {
    components: {
        TeacherLessons,
    },
    data: () => ({
        headers: [
            {text: 'Teacher #', align: 'start', value: 'teacher_num', width: '110'},
            {text: 'Last Name', value: 'last_name' },
            {text: 'First Name', value: 'first_name' },
            {text: 'Date of Birth', value: 'date_of_birth', width: '120', sortable: false},
            {text: 'User Name', value: 'user_name', width: '100px', sortable: false},
            {text: 'Actions', value: 'actions', sortable: false, width: '320px' }
        ],
        headersArchive: [
            {text: 'Teacher #', align: 'start', sortable: 'false', value: 'teacher_num', width: '100'},
            {text: 'Last Name', value: 'last_name', width: '150'},
            {text: 'First Name', value: 'first_name', width: '100'},
            {text: 'Date of Birth', value: 'date_of_birth', width: '150'},
            {text: 'User Name', value: 'user_name', width: '120'},
            {text: 'Actions', value: 'actions', sortable: 'false', }
        ],
        
        employees: [],
        employeesArchive: [],        
       
        editedIndex: -1,
        search: '',
        defaultEmployee: {
            id: '',
            first_name: '',
            last_name: '',
            teacher_num: '',
            date_of_birth: '',
            user_name: '',
            post_id: '',
        },        
        editedEmployee: {},
        selectFormClass: '',
        selectEditClass: '',
        formClasses: [],
        editLessonClasses: [],
        editLessonClass: '',
        editLessonSubject: {id: '', title: ''},
        loadingEmployees: true,       
        saving: false,
        saved: false,
        saveError: false,
        saveProgress: '',
       
        dialogResetPassword: false,
        resetPassword: false,
        cancelButton: false,
        okCloseButton: false,
        resetProgress: false,
        dialogResetTitle: '',
        okButton: false,
        snackbar: false,
        snackbarText: '',
        dialogEdit: false,
        menuBirthDate: false,
        dialogNewEmployee: false,
        employeePosts: [],
        tab: null,
        disable: false,
        disableReset: false,
        
        alert: {
            display:false,
            type: 'success',
            text: 'Teacher added successfully!'
        },
        resetForm: false,
        rules: [val => !!val || 'Required.'],
        saveButtonText: 'Add Teacher',
    }),
    created () {
        this.initialize();
    },
    watch: {
        

        menuBirthDate (val) {
            val && setTimeout(() => (this.$refs.pickerDateOfBirth.activePicker = 'YEAR'))
        }
    },
    computed: {
        ...mapGetters({
            getSelectedEmployeeId: 'admin/getSelectedEmployeeId',
            getAcademicYearId: 'admin/getAcademicYearId',
            employeeSelected: 'admin/getEmployeeSelected',
            userName: 'auth/getUserName',              
        }),

       
        
        formTitle () {
            return this.editedIndex === -1 ? 'New Teacher' : 'Edit Teacher';
        }
    },
    methods: {
        ...mapActions({
            getEmployees: 'admin/getEmployees',
            getFormClasses: 'admin/getFormClasses',
            resetPasswordEmployee: 'admin/resetPasswordEmployee',
            updateEmployee: 'admin/updateEmployee',
            addEmployee: 'admin/addEmployee',
            getEmployeePosts: 'admin/getEmployeePosts',   
            getCurrentAcademicTerm: 'admin/getCurrentAcademicTerm',  
        }),

        ...mapMutations({
            setFormTeacherAssignment: 'admin/setFormTeacherAssignment',
            setEmployeeSelected: 'admin/setEmployeeSelected',
            setAcademicYearId: 'admin/setAcademicYearId',
        }),

        async initialize () {
            // if(this.userName == 'Deans/HODs') this.disable = true;
            this.formClasses = [];
            this.employees = [];
            this.editedEmployee = Object.assign({}, this.defaultEmployee);
            try {
                const [
                    { data: dataEmployees },
                    { data: dataFormClasses },
                    { data: dataEmployeePosts },
                    { data: dataTerm },
                    
                ] = await Promise.all([
                    this.getEmployees(),
                    this.getFormClasses(),
                    this.getEmployeePosts(),
                    this.getCurrentAcademicTerm(),
                ])
                // console.log(dataEmployees);
                // this.employees = dataEmployees;
                
                this.mapEmployees(dataEmployees);

                this.loadingEmployees = false;

                this.parseFormClasses(dataFormClasses);
                
                this.employeePosts = dataEmployeePosts;

                const { current_term } = dataTerm;

                this.setAcademicPeriod(current_term);



            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        parseFormClasses (data) {
            data.forEach(record => {
                if(record.class_level) {
                    this.formClasses.push({
                        form_class_id: record.id,
                        class_group: record.class_group,
                        class_name: record.class_name
                    });
                    this.editLessonClasses.push({
                        form_class_id: record.id,
                        class_group: record.class_group,
                        class_name: record.class_name
                    });
                }
            });
        },

        mapEmployees (data) {
            this.employees = [];
            this.employeesArchive = [];
            data.forEach(employee => {
                if(employee.deleted_at || employee.teacher_num > 999) this.employeesArchive.push(employee);
                else this.employees.push(employee);
            })
        },

        setAcademicPeriod (current_term) {
            let academicYear = current_term.academic_year_id.toString();
            this.selectedYear = academicYear.substring(0,4) + '-' + academicYear.substring(4);
            this.selectedTerm = current_term.term;
            this.possibleAttendance = current_term.possible_attendance;
            this.date = current_term.new_term_beginning;
            this.setAcademicYearId(current_term.academic_year_id);
        },

        async displayResetPassword (item) {
            //console.log(item);
            this.okCloseButton = true;
            this.cancelButton = true;
            this.dialogResetTitle = `Reset ${item.first_name} ${item.last_name} password ?`;
            this.dialogResetPassword = true;
            this.setEmployeeSelected(item);
            
        },

        closeResetPassword () {
            this.dialogResetPassword = false;
        },

        async resetPasswordConfirm () {
            this.dialogResetTitle = `Resetting ${this.employeeSelected.first_name} ${this.employeeSelected.last_name} password..`
            this.resetProgress = true;
            try {
                let response = await this.resetPasswordEmployee();
                this.resetProgress = false;
                this.snackbarText = `${this.employeeSelected.first_name} ${this.employeeSelected.last_name} password reset.`
                console.log(response.status);
                this.$nextTick(() => {
                    this.snackbar = true;
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.resetProgress = false;
                this.snackbarText = 'Error occured.';
                this.$nextTick(() => {
                    this.snackbar = true;
                })
                
            }
            this.dialogResetPassword = false;
        },

        addNewTeacher () {
            this.editedEmployee = Object.assign({}, this.defaultEmployee);
            this.saveButtonText = 'Add Teacher';
            this.dialogEdit = true;
        },

        editEmployee (item) {
            this.editedIndex = this.employees.indexOf(item);            
            this.editedEmployee = Object.assign({}, item);
            this.saveButtonText = 'Update Teacher';
            this.dialogEdit = true;
        },

        editEmployeeArchive (item) {
            this.editedIndex = this.employeesArchive.indexOf(item);
            this.editedEmployee = Object.assign({}, item);
            this.saveButtonText = 'Update Teacher';
            this.dialogEdit = true;
        },

        saveDateOfBirth (date) {
            this.$refs.menuBirthDate.save(date);                    
        },

        closeEdit () {
            this.alert.display = false;
            this.dialogEdit = false;
            this.resetForm = false;
            this.$refs['firstName'].reset();
            this.$refs['lastName'].reset();
            this.$refs['dateOfBirth'].reset();
            this.$nextTick(() => {
                this.editedEmployee = Object.assign({}, this.defaultEmployee);
                this.editedIndex = -1;
                this.setSaveStatus('closed');
            })
        },

        async saveEmployee () {
            console.log('saving employee')
            if(!this.editedEmployee.first_name || !this.editedEmployee.last_name || !this.editedEmployee.date_of_birth){
                this.alert.type = 'error';
                this.alert.text = 'Please fill all required fields.'
                this.alert.display = true;
                this.$refs['firstName'].validate(true);
                this.$refs['lastName'].validate(true);
                this.$refs['dateOfBirth'].validate(true);
                return;
                
            }
            this.setSaveStatus();           
            this.setEmployeeSelected(this.editedEmployee);
            // console.log(this.editedEmployee);
            console.log(this.editedIndex);           

            try {
                this.editedIndex === -1 ? await this.addEmployee() : await this.updateEmployee();
                const { data } = await this.getEmployees();                             
                this.mapEmployees(data);
                this.setSaveStatus('saved');
                this.alert.type = 'success';
                this.alert.text = this.editedIndex === -1 ? 'Teacher added succesfully' : 'Teacher updated succesfully';
                this.alert.display = true;
                this.resetForm = true;
            } catch (error) {
                this.alert.type = 'error';
                this.alert.text =  'An error occured, data not saved.';
                if(error.response){
                    console.log(error.response);
                    this.alert.text = error?.response.data.message;
                }
                else console.log(error);
                this.alert.display = true;
                this.setSaveStatus('error', '');
            }
        },

        setSaveStatus (status = 'saving', message = ''){
            this.saving = false;
            this.saved = false;
            this.saveError = false;
            this.saveProgress = '';
            switch (status) {
                case 'saving':
                    this.saving = true;
                    this.saveProgress = status;
                    if(message) this.saveProgress = message
                    break;
                case 'saved':
                    this.saved = true;
                    this.saveProgress = status;
                    if(message) this.saveProgress = message
                    break;
                case 'error':
                    this.saveError = true;
                    this.saveProgress = status;
                    if(message) this.saveProgress = message
                    break;
                case 'closed':
                    break;
            }
        },

        setClearForm () {
            this.editedEmployee = Object.assign({}, this.defaultEmployee);
            this.$refs['firstName'].reset();
            this.$refs['lastName'].reset();
            this.$refs['dateOfBirth'].reset();
            this.alert.display = false;
        },

        async updateFormClasses () {
            console.log('updating form classes...');
            this.formClasses = [];
            try {
                const { data } = await this.getFormClasses();
                this.parseFormClasses(data)
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
            }
        }
        
    }
}
</script>