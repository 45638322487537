<template>
    <v-row justify="center">
        <v-col>
            <v-card
                class="mx-auto"
                width="900"
            >
                <v-card-title class="d-flex white--text  py-2 px-0 primary">
                    <v-spacer></v-spacer>
                    <div class="font-weight-light text-uppercase">Weekly Tests</div>
                    <v-spacer></v-spacer>
                    <v-btn
                        text                
                        x-small
                        color="white"
                        @click="close"
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn> 
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            <v-date-picker
                                v-model="weekEndDate"
                                :allowed-dates="allowedDates"
                                :min="allowedDatesMin"
                                :max="allowedDatesMax"
                                :show-current="false"
                                 @click:date="clickDate"
                            ></v-date-picker>
                        </v-col>

                        <v-col cols="7">
                            <v-data-table
                                :headers="headers"
                                :items="weeklyTestSubjects"
                                :items-per-page="-1"
                                :loading="loading"
                                hide-default-footer
                                hide-default-header
                            >
                                <template v-slot:top>
                                    <v-toolbar flat>
                                        <v-toolbar-title></v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <!-- <WeeklyTestAddSubject
                                            :dialog="dialogAddSubject"
                                            :subjects="subjects"
                                            :week-end-date="weekEndDate"
                                            @update-subjects="updateSubjects"
                                            v-if="addSubject"
                                        ></WeeklyTestAddSubject> -->
                                    </v-toolbar>
                                </template>

                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ item.count }}</td>
                                        <td>{{ item.title }} </td>
                                        <td class="d-flex justify-end align-center">
                                            <WeeklyTestMarkSheet
                                                :subject="item"
                                                :week-end-date="weekEndDate"
                                                btn-text="Edit/View Marks"
                                                cssClass="mr-4"
                                            ></WeeklyTestMarkSheet>
                                            <!-- <v-btn 
                                                depressed 
                                                small 
                                                icon
                                                @click="deleteSubject(item)"
                                            >
                                                <v-icon>mdi-trash-can</v-icon>
                                            </v-btn> -->
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-col>
                        
                    </v-row>
                </v-card-text>

                <v-overlay :value="overlay" absolute>
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </v-overlay>

                <v-overlay absolute :value="overlayDeleteConfirm">
                    <v-card light>
                        <v-alert text type="warning" color="warning">
                            <div>Are you sure you want to delete this subject and all the marks? This action is not reversible.</div>
                            <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>
                            <v-row no-gutters>
                                <v-col class="d-flex justify-end">
                                    <v-btn color="warning" class="mr-4" outlined @click="deleteSubjectConfirm">Yes Delete</v-btn>
                                    <v-btn depressed color="white" light @click="overlayDeleteConfirm = false">Cancel</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-card>
                </v-overlay>

                
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
// import WeeklyTestAddSubject from './WeeklyTestAddSubject.vue';
import WeeklyTestMarkSheet from './WeeklyTestMarkSheet.vue';
export default {
    name: 'WeeklyTest',

    components: {
        // WeeklyTestAddSubject,
        WeeklyTestMarkSheet,
    },

    data: function () {
        return {
            weekEndDate: new Date().toISOString().substring(0,7),
            menu: false,
            headers: [
                { text: '#', sortable: false, value: 'count' },
                { text: 'Subject', sortable: false, value: 'title' },
                // { text: 'Max Mark', sortable: false, value: 'max_mark' },
                // { text: '', value: 'actions', sortable: false }
            ],
            weeklyTestSubjects: [],
            loading: false,
            dialogAddSubject: false,
            overlay: false,
            subjects: [],
            dialogMarkSheet: false,
            formTeacherClass: null,
            overlayDeleteConfirm: false,
            deleteSubjectSelected: null,
            addSubject: false,
            allowedDatesMin: null,
            allowedDatesMax: null,
        }
    },

    computed: {
        ...mapGetters({
            weeklyTestSubject: 'weeklyTests/getWeeklyTestSubject',
            employeeId: 'auth/getEmployeeId',
            getWeeklyTestSelected: 'weeklyTests/getWeeklyTestSelected',
        })
    },

    created: function () {
        this.initialize();
    },

    methods: {
        ...mapActions({
            getSubjects: 'termReports/getSubjects',
            getWeeklyTest: 'weeklyTests/getWeeklyTest',
            getWeeklyTestSubjects: 'weeklyTests/getWeeklyTestSubjects',
            getFormTeacherClasses: 'termReports/getAssignedFormTeacherClass',
            deleteSubjectRecord: 'weeklyTests/deleteSubject',
            getCurrentTerm: 'weeklyTests/getCurrentTerm',
        }), 

        ...mapMutations({
            setWeeklyTestSelected: 'weeklyTests/setWeeklyTestSelected',
        }),

        async initialize () {
            this.overlay = true;

            this.weekEndDate = this.nextFriday();

            try {
                const [
                    { data: dataSubjects },
                    { data: dataFormTeacherClasses },
                    { data: dataCurrentTerm },
                ] = await Promise.all([
                    this.getSubjects(),
                    this.getFormTeacherClasses(this.employeeId),
                    this.getCurrentTerm(),
                ])
                
                this.subjects = dataSubjects;

                this.formTeacherClass = dataFormTeacherClasses[0];

                this.setWeeklyTestSelected({
                    weekEndDate: this.weekEndDate,
                    formClassId: this.formTeacherClass.form_class_id,
                    employeeId: this.employeeId,
                    className: this.formTeacherClass.class_name
                })

                this.setWeeklyTestSubjects();

                this.allowedDatesMin = dataCurrentTerm.start_date;
                this.allowedDatesMax = dataCurrentTerm.end_date;
                
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.overlay = false;
        },

        async setWeeklyTestSubjects () {
            this.loading = true;
            this.weeklyTestSubjects = [];
            try {
                const { data } = await this.getWeeklyTestSubjects();
                // console.log('weeklyTestSubjects: ', data)
                data.forEach((value, index) => value.count = index + 1 )
                this.weeklyTestSubjects = data;

            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
            }
            this.loading = false;
        },

        allowedDates (date) {
            const inputDate = new Date(date);
            return inputDate.getDay() === 4;
        },

        nextFriday () {
            const today = new Date();
            const dayOfWeek = today.getDay();
            let year = today.getFullYear();
            let month = String(today.getMonth() + 1).padStart(2, '0');
            let day = String(today.getDate()).padStart(2,'0');
            // if(dayOfWeek - 5 <= 1 ) {
            //     const prevFriday = new Date(today.setDate(today.getDate() - ))
            //     return `${year}-${month}-${day}`;
            // }
            const daysUntilNextFriday = (5 - dayOfWeek + 7) % 7;
            const nextFriday = new Date(today.setDate(today.getDate() + daysUntilNextFriday));
            year = nextFriday.getFullYear();
            month = String(nextFriday.getMonth() + 1).padStart(2, '0');
            day = String(nextFriday.getDate()).padStart(2,'0');
            return `${year}-${month}-${day}`;
        },

        async updateSubjects () {
            this.dialogAddSubject = false;
            // this.overlay = true;
            await this.setWeeklyTestSubjects();
            // this.overlay = false;
        },

        clickDate (date) {
            this.weekEndDate = date;
            this.setWeeklyTestSelected({
                weekEndDate: this.weekEndDate,
                formClassId: this.formTeacherClass.form_class_id,
                employeeId: this.employeeId,
                className: this.formTeacherClass.class_name
            })
            this.setWeeklyTestSubjects();
        },

        close () {
            this.$emit('show-main-menu')
        },

        closeMarkSheet () {
            this.dialogMarkSheet = false;
        },

        deleteSubject (subject) {
            this.deleteSubjectSelected = subject;
            this.overlayDeleteConfirm = true;
        },

        async deleteSubjectConfirm () {
            this.overlayDeleteConfirm = false;
            if(!this.deleteSubjectSelected) return;

            this.setWeeklyTestSelected({
                weekEndDate: this.weekEndDate,
                subjectId: this.deleteSubjectSelected.subject_id
            })
            try {
                await this.deleteSubjectRecord();
                this.updateWeeklyTestSubjects()
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
            }
        },

        updateWeeklyTestSubjects () {
            const index = this.weeklyTestSubjects.findIndex(testSubject => testSubject.subject_id === this.deleteSubjectSelected.subject_id);
            if(index !== -1) {
                this.weeklyTestSubjects.splice(index, 1);
            }
        }
    }
}
</script>
