import axios from 'axios'

export default{
    namespaced: true,
    
    state: { 
        weeklyTest: false,
        weeklyTestSubject: null, 
        weeklyTestSelected: null, 
        weeklyTestSubjectRecord: null,
        weeklyTestRecord: null,
    },

    getters: {
        getWeeklyTest (state) {
            return state.weeklyTest
        },

        getWeeklyTestSubject (state) {
            return state.weeklyTestSubject
        },

        getWeeklyTestSelected (state) {
            return state.weeklyTestSelected
        },

        getWeeklyTestSubjectRecord (state) {
            return state.weeklyTestSubjectRecord
        },

        getWeeklyTestSubjectRecords (state) {
            return state.weeklyTestSubjectRecords
        },

        getWeeklyTestRecord (state) {        
            return state.weeklyTestRecord
        },
        
    },

    mutations: {
        setWeeklyTest (state, value) {
            state.weeklyTest = value
        },

        setWeeklyTestSubject (state, value) {
            state.weeklyTestSubject = value
        },

        setWeeklyTestSelected (state, value) {
            state.weeklyTestSelected = value
        },

        setWeeklyTestSubjectRecord (state, value) {
            state.weeklyTestSubjectRecord = value
        },

        setWeeklyTestRecord (state, value) {
            state.weeklyTestRecord = value
        }

    },

    actions: {
        getWeeklyTest ({ getters }) {
            const url = 'api/weekly-test';
            return axios.get(url, {
                params: getters.getWeeklyTestSubject
            });
        },

        getWeeklyTestSubjects ({ getters }) {
            const url = 'api/weekly-test-subjects';
            return axios.get(url, {
                params: getters.getWeeklyTestSelected
            })
        },

        postWeeklyTestSubjectRecord ({ getters}) {
            const url = 'api/weekly-test-subject';
            return axios.post(url, getters.getWeeklyTestSubjectRecord);
        },

        deleteSubject ({ getters}) {
            const url = 'api/weekly-test-subject';
            return axios.delete(url, { data: getters.getWeeklyTestSelected })
        },

        importWeeklyTests ({ getters }) {
            const url = 'api/import-weekly-tests';
            return axios.post(url, getters.getWeeklyTestRecord);
        },

        getTeacherSubjects ({ getters }) {
            const url = 'api/teacher-subjects';
            return axios.get(url, {
                params: {
                    employeeId: getters['auth/getEmployeeId']
                }
            });
        },

        getCurrentTerm () {
            const url = 'api/weekly-test-current-term';
            return axios.get(url);
        }
    }
}